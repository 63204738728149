import { NavigationProp, RouteProp, useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import DraggableFlatList, {
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { Layout } from 'react-native-reanimated';
import { Colors, Text, View } from 'react-native-ui-lib';
import {
  useContainerListener,
} from '../dataModel';
import {
  convertInfoToPath,
  convertPathToInfo,
  convertRouteParamsToInfo,
  getContainerTypeFromPath,
  isEditingAllowed,
} from '../helperFunctions';
import { ContainerType, StanceType, ElementPath, navigationProps, FirestoreConstants, PathConstants, StatementRole } from '../typings/types';
import { Container, Statement } from './Containers';
import { FloatingActionButton } from './FloatingActionButton';
import { useIsMobile, useIsTablet } from '../MediaQueries';
import { Ionicons } from '@expo/vector-icons';
import LinearGradient from 'react-native-linear-gradient';
import { THREAD_WIDTH } from '../styles';
import { strings } from '../localization/localization.web';
import { NothingHereInformation } from './Alert';
import { Footer } from './Footer';
import { ScrollView } from 'react-native-gesture-handler';
import { Dimensions } from 'react-native';
import { doc, DocumentData, getDoc, getFirestore } from 'firebase/firestore';
import { useUserState } from '../firebaseWrapper/firebaseWrapper';
import { StatementViewDesktop, StatementViewMobile, StatementViewProps } from './StatementView';

export function StatementScreen({
  navigation,
  route,
  location,
  statementId,
  memberId,
  groupId,
  groupAdminLoggedIn
}: StatementViewProps): JSX.Element {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const routeMemberId = route?.params?.member;
  const routeGroupId = route?.params?.groupId;
  const [group, setGroup] = React.useState<DocumentData>();
  const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);
  const [user, userLoading] = useUserState();
  const info: ElementPath = convertRouteParamsToInfo(route?.params, location, statementId, ContainerType.STATEMENT, groupId);
  const { container, loading, error } = useContainerListener(info);

  useEffect(() => {
    if (groupId !== undefined) {
      const documentReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId);
      getDoc(documentReference).then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          setGroup({ ...documentSnapshot.data(), id: documentSnapshot.id });
        } else {
          console.log('No such document!');
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
      });
    }
  }, []);

  useEffect(() => {
    if (group && user) {
      setAdminLoggedIn(group.admin === user.uid);
    }
  }, [group, user]);

  return (
    <View flex style={{ backgroundColor: Colors.primaryBG }}>
      <View style={{
        flex: 1,
        backgroundColor: Colors.secondaryBG,
        borderRadius: 15,
        marginHorizontal: isMobile ? 0 : 5,
        marginVertical: 5,
        overflow: 'hidden',
      }}>
        {isMobile || isTablet ? (
          <>
            <ScrollView showsVerticalScrollIndicator={false} style={{margin: 10}}>
              <StatementViewMobile
                navigation={navigation}
                route={route}
                location={location}
                statementId={statementId}
                type={ContainerType.STATEMENT}
                memberId={memberId || routeMemberId}
                groupId={groupId || routeGroupId}
                groupAdminLoggedIn={adminLoggedIn}
              />
              {!loading && container.getChildData().length < 1 ?
                <ScrollView showsVerticalScrollIndicator={false}>
                  <NothingHereInformation text={strings.no_arguments_info} />
                  <View marginT-100 />
                  <Footer navigation={navigation} />
                </ScrollView>
                : container.getChildData().length < 3 ?
                  <>
                    <View style={{ height: Dimensions.get('window').height }} />
                    <Footer navigation={navigation} />
                    </>
                  :
                  <Footer navigation={navigation} />
              }            
            </ScrollView>
          </>
        ) : (
          <>
            <ScrollView showsVerticalScrollIndicator={false} style={{margin: 10}}>
              <StatementViewDesktop
                navigation={navigation}
                route={route}
                location={location}
                statementId={statementId}
                type={ContainerType.STATEMENT}
                memberId={memberId || routeMemberId}
                groupId={groupId || routeGroupId}
                groupAdminLoggedIn={adminLoggedIn}
              />
              {!loading
                && container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.SUPPORT).length === 0
                && container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.OPPOSE).length === 0 ?
                <>
                  <NothingHereInformation text={strings.no_arguments_info} />
                  <View marginT-100 />
                  <Footer navigation={navigation} />
                </>
                : container.getChildData().length < 3 ?
                  <>
                    <View style={{ height: Dimensions.get('window').height }} />
                    <Footer navigation={navigation} />
                  </>
                  :
                  <Footer navigation={navigation} />
              }
            </ScrollView>
          </>
        )}
      </View>
      <FloatingActionButton
        navigation={navigation}
        parentInfo={info}
        possibleTypes={
          container.getType() === ContainerType.CATEGORY
            ? [
              ContainerType.CATEGORY,
              ContainerType.STATEMENT,
            ]
            : container.getType() === ContainerType.STATEMENT ?
              [
                ContainerType.ARGUMENT,
                ContainerType.SOURCE,
              ]
              : container.getType() === ContainerType.SOURCE ?
                [
                  ContainerType.ARGUMENT,
                ]
                :
                [
                  ContainerType.STATEMENT,
                  ContainerType.SOURCE,
                ]
        }
        groupId={'groupId' in info ? info.groupId : undefined}
        memberId={memberId}
        topicId={container.getTopics()[0]}
      />
    </View>
  );
}

export function ParentViewMobile(
  {
    navigation,
    route,
    location,
    statementId,
    type,
    groupId,
    memberId,
    groupAdminLoggedIn,
    argumentsCollapsed,
    setArgumentsCollapsed,
    role
  }: StatementViewProps
): JSX.Element {
  const info = convertRouteParamsToInfo(route?.params, location, statementId, type, groupId);
  const parentInfo = null;

  return (
    <View style={{
      backgroundColor: Colors.secondaryBG,
      borderRadius: 15,
    }}>
      <View margin-0 style={{ flexDirection: 'column-reverse' }}>
        <Statement
          info={info}
          parentInfo={parentInfo}
          navigation={navigation}
          drag={undefined}
          showStanceIndicator={false}
          showHeader={role !== StatementRole.PREMISE}
          showDetails={role !== StatementRole.PREMISE}
          groupId={groupId}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
          handleArgumentsPress={setArgumentsCollapsed ? () => setArgumentsCollapsed(!argumentsCollapsed) : undefined}
          role={role}
        />
      </View>
    </View>
  );
}

export function ParentViewDesktop({ navigation, route, location, statementId, type, groupId, memberId, groupAdminLoggedIn, argumentsCollapsed, setArgumentsCollapsed, role }: StatementViewProps): JSX.Element {
  const info = convertRouteParamsToInfo(route?.params, location, statementId, type, groupId);
  const parentInfo = null;

  return (
    <View style={{
      backgroundColor: Colors.secondaryBG,
      borderRadius: 15,
    }}>
      <View margin-0 style={{ flexDirection: 'column-reverse' }}>
        <Statement
          info={info}
          parentInfo={parentInfo}
          navigation={navigation}
          drag={undefined}
          showStanceIndicator={false}
          showHeader={role !== StatementRole.PREMISE}
          showDetails={role !== StatementRole.PREMISE}
          groupId={groupId}
          memberId={memberId}
          groupAdminLoggedIn={groupAdminLoggedIn}
          handleArgumentsPress={setArgumentsCollapsed ? () => setArgumentsCollapsed(!argumentsCollapsed) : undefined}
          role={role}
        />
      </View>
    </View>
  );
}

type LineProps = {
  stance: StanceType;
  lastLine: boolean;
  isActive?: boolean;
  children?: JSX.Element;
};

export function LineToSideOfViewMobile(props: LineProps): JSX.Element {
  return (
    <LineToSideOfViewDesktop stance={props.stance} lastLine={props.lastLine} children={props.children} isActive={props.isActive} />
  );
}
export function LineToSideOfViewDesktop(props: LineProps): JSX.Element {
  const isMobile = useIsMobile();
  const color = isMobile ? Colors.iconSecondary :
    props.stance === StanceType.SUPPORT ? Colors.greenBG : Colors.redBG;

  return (
    <View flex style={{ flexDirection: 'row-reverse', marginLeft: 0.75, marginRight: 10 }}>
      <View flex marginT-5 marginB-5 style={{ zIndex: props.isActive ? 1 : 0 }}>
        {props.children}
      </View>
      <View style={{ marginLeft: 10 }}>
        <View
          width={THREAD_WIDTH}
          style={{
            width: THREAD_WIDTH,
            height: 5,
            left: 0,
            backgroundColor: color
          }}
        />
        {props.children ? (
          <View
            style={{
              width: 15,
              height: 20,
              left: 0,
              marginRight: -5,
              borderBottomLeftRadius: 13,
              borderBottomColor: color,
              borderBottomWidth: THREAD_WIDTH,
              borderLeftColor: color,
              borderLeftWidth: THREAD_WIDTH,
            }} />
        ) : undefined}
        {/* <View style={{height: 25}}/> */}
        <View
          flex
          width={THREAD_WIDTH}
          style={{
            marginTop: -10,
            width: THREAD_WIDTH,
            backgroundColor: props.lastLine ? 'transparent' : color,
          }} />
      </View>
    </View>
  );
}

type StanceProp = {
  stance: StanceType;
}
export function ArgumentThreadHeader({ stance }: StanceProp): JSX.Element {
  return (
    <View marginL-0 bg-secondaryBG>
      <ImplicationIndicator stance={stance} />
    </View>
  )
}

export function ImplicationIndicator({ stance }: StanceProp): JSX.Element {

  const arrowHeadSize = 15;
  const color = stance === StanceType.SUPPORT ? Colors.greenBG : stance === StanceType.OPPOSE ? Colors.redBG : Colors.iconSecondary;

  return (
    <>
      <Ionicons
        name="md-triangle"
        size={arrowHeadSize}
        color={color}
        style={{ padding: 0, marginLeft: THREAD_WIDTH + 2, marginBottom: 0, marginTop: -2, position: 'relative' }}
      />
      <View
        style={{
          marginLeft: THREAD_WIDTH + arrowHeadSize / 2 + 1.25,
          marginTop: -5,
          borderLeftWidth: THREAD_WIDTH,
          borderColor: color,
          height: 10,
          width: 5
        }}
      />
    </>
  )
}

export function ThreadConnector({ stance }: StanceProp): JSX.Element {

  const color = stance === StanceType.SUPPORT ? Colors.greenBG : stance === StanceType.OPPOSE ? Colors.redBG : Colors.iconSecondary;

  return (
    <LinearGradient
      locations={[0.5, 1]}
      colors={[Colors.iconSecondary, color]}
      start={{ x: 0.0, y: 0.0 }}
      end={{ x: 0.0, y: 1.0 }}
      style={{ marginTop: -20, width: THREAD_WIDTH, zIndex: -1 }}
    >
      <View style={{ marginLeft: 5, height: 40, width: THREAD_WIDTH }} />
    </LinearGradient>
  )
}