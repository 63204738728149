import { TransitionPresets, createStackNavigator } from '@react-navigation/stack';
import React, { useState } from 'react';
import { TabView } from 'react-native-tab-view';
import { ActionSheet, Colors, Text, TouchableOpacity, View } from 'react-native-ui-lib';
import ProfileIcon from '../../assets/res/profile.svg';
import SettingsIcon from '../../assets/res/settings.svg';
import { BookmarkedListQuery, PublishedListQuery, useUserName, useUserState } from '../firebaseWrapper/firebaseWrapper';
import { applyOnPlatform } from '../helperFunctions';
import { LocalizedStringsContext } from '../localization/localization.native';
import { dbPath, navigationProps, PathConstants } from '../typings/types';
import { CreateDialog, NotYetImplementedDialog } from './Dialogs';
import { LazyList } from './FeedList';
import { Header } from './Header';
import { Settings } from './Settings';
import { StatementOrArgumentOrPersonalOrTopicScreen } from './StatementView';
import { useIsMobile } from '../MediaQueries';
import { strings } from '../localization/localization.web';
import { Pressable } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';

type ProfileParamList = {
  ProfileContent: undefined;
  Settings: undefined;
  StatementList: { path: dbPath };
  CreateDialog: undefined,
};

export function OwnProfile(): JSX.Element {
  const Stack = createStackNavigator<ProfileParamList>();

  return (
    <Stack.Navigator
      initialRouteName="ProfileContent"
      screenOptions={navigationProps => Header(navigationProps)}>
      <Stack.Screen name="ProfileContent" component={OwnProfileContent} />
      <Stack.Screen
        name="settings"
        component={Settings}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="StatementView"
        component={StatementOrArgumentOrPersonalOrTopicScreen}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="CreateDialog"
        component={CreateDialog}
        options={{
          presentation: 'transparentModal',
          headerLeft: () => undefined,
          ...TransitionPresets.ModalFadeTransition,
        }}
      />
    </Stack.Navigator>
  );
}

export function OwnProfileContent({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const strings = React.useContext(LocalizedStringsContext);
  const [index, setIndex] = useState(0);
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const [routes] = useState([
    { key: 'published', title: strings.published },
    { key: 'bookmarked', title: strings.bookmarks },
  ]);

  const isMobile = useIsMobile();

  return (
    <View flex bg-primaryBG>
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: isMobile ? 0 : 20,
          marginVertical: 5,
          marginHorizontal: isMobile ? 0 : 5,
          overflow: 'hidden',
        }}
      >
        <ProfileDetails navigation={navigation} route={route} />
        <View flex style={{ backgroundColor: Colors.secondaryBG, borderRadius: 20 }}>
          <View row center style={{ justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 25, marginTop: 15, borderRadius: 20 }}>
            <Text h4 bold textPrimary>
              {`${strings.statements} & ${strings.arguments}`}
            </Text>
            <Pressable
              onPress={() => setShowDropdownOptions(true)}>
              <View row style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                <Text textSecondary style={{}}>
                  {index === 0 ? strings.published : strings.bookmarks}
                </Text>
                <Entypo name="chevron-small-down" size={24} color={Colors.textSecondary} />
              </View>
            </Pressable>
          </View>
          <TabView
            lazy={true}
            navigationState={{ index, routes }}
            renderScene={props => {
              switch (props.route.key) {
                case 'published':
                  return <PublishedList navigation={navigation} route={route} />;
                case 'bookmarked':
                  return <BookmarkList navigation={navigation} route={route} />;
                default:
                  return null;
              }
            }}
            renderTabBar={() => null}
            onIndexChange={setIndex}
          />
        </View>
        <ActionSheet
          visible={showDropdownOptions}
          dialogStyle={{
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
            backgroundColor: Colors.quaternaryBG,
          }}
          options={[
            {
              label: strings.published,
              onPress: () => {
                setIndex(0);
              },
            },
            {
              label: strings.bookmarks,
              style: { backgroundColor: 'red' },
              onPress: () => {
                setIndex(1);
              },
            },
          ]}
          onDismiss={() => setShowDropdownOptions(false)}
        />
      </View>
    </View>
  );
}

type ProfileDetailsProps = {
  navigation: any;
  route: any;
  self?: boolean;
};
function ProfileDetails({ navigation, route, self = true }: ProfileDetailsProps): JSX.Element {

  const [userName] = self ? useUserName() : useUserName(route.params?.userId);
  const isMobile = useIsMobile();
  const [user, loading] = useUserState();

  return (
    <View style={{
      backgroundColor: isMobile ? Colors.primaryBG : undefined,
      overflow: 'hidden',
    }}>
      <View flexS row margin-15 marginB-0 centerV>
        <View row flexG centerV>
          <ProfilePicture userName={userName} />
          <View flex row style={{ alignItems: 'flex-end' }}>
            <Text textPrimary h3 bold>
              {userName}
            </Text>
            {!self ?
              <>
                <Pressable
                  onPress={() => NotYetImplementedDialog('Blocking', strings)}>
                  <Text textSecondary marginL-5 marginB-1>
                    {strings.block}
                  </Text>
                </Pressable>
                <Pressable
                  onPress={() => NotYetImplementedDialog('Muting', strings)}>
                  <Text textSecondary marginL-5 marginB-1>
                    {strings.mute}
                  </Text>
                </Pressable>
              </>
              : null
            }
          </View>
        </View>
        <View flexS right marginR-5>
          {self && !user.isAnonymous ?
            <Pressable onPress={() => navigation.navigate('settings')}>
              <MaterialIcons name="settings" size={24} color={Colors.iconSecondary} />
            </Pressable>
            : null}
        </View>
      </View>
      <View row marginL-15 marginB-20>
        <Pressable
          onPress={() => NotYetImplementedDialog(strings.following, strings)}>
          <View row marginH-10>
            <Text textPrimary bold marginR-5>
              {'0'}
            </Text>
            <Text textSecondary>
              {strings.following}
            </Text>
          </View>
        </Pressable>
        <Pressable
          onPress={() => NotYetImplementedDialog(strings.following, strings)}>
          <View row marginH-10>
            <Text textPrimary bold marginR-5>
              {'0'}
            </Text>
            <Text textSecondary>
              {strings.followed}
            </Text>
          </View>
        </Pressable>
      </View>
    </View>
  )
}

function BookmarkList({ navigation, route }: navigationProps): JSX.Element {
  return (
    <LazyList
      navigation={navigation}
      route={route}
      query={BookmarkedListQuery()}
      location={PathConstants.PUBLIC}
      showStatementView={true}
    />
  );
}

function PublishedList({ navigation, route }: navigationProps): JSX.Element {
  return (
    <LazyList
      navigation={navigation}
      route={route}
      query={PublishedListQuery()}
      location={PathConstants.PUBLIC}
      showStatementView={true}
    />
  );
}

export function ProfilePicture({ userName }: any): JSX.Element {
  // TODO: Fill white??
  let content = <MaterialCommunityIcons name="account" size={30} color={Colors.quinaryBG} style={{ margin: 7 }} />;
  if (userName !== 'unknown user') {
    if (userName === undefined || userName === null) {
      userName = 'unknown user';
    }
    let lettersToDisplay = '';
    if (userName.split(' ', 2).length > 1) {
      lettersToDisplay =
        userName.split(' ')[0].substring(0, 1) +
        userName.split(' ')[1].substring(0, 1);
    } else {
      lettersToDisplay = userName.substring(0, 2);
    }
    content = (
      // previously: height={25} width={25}
      <Text quinaryBG margin-10 center text70 bold style={{ height: 25, width: 25 }}>
        {lettersToDisplay}
      </Text>
    );
  }
  return (
    <View bg-quaternaryBG margin-10 height={45} width={45} style={{ borderRadius: 30 }}>
      {content}
    </View>
  );
}

export function ProfileView({ navigation, route }: navigationProps): JSX.Element {
  const strings = React.useContext(LocalizedStringsContext);
  const [userName] = useUserName(route.params?.userId);
  const isMobile = useIsMobile();

  return (
    <View flex bg-primaryBG>
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: isMobile ? 0 : 20,
          marginVertical: 5,
          marginHorizontal: isMobile ? 0 : 5,
          overflow: 'hidden',
        }}
      >
        <ProfileDetails navigation={navigation} route={route} self={false} />
      </View>
    </View>
  );
}
