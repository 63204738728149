
import { getGroupStatementsQuery } from "../firebaseWrapper/firebaseWrapper";
import { convertInfoToPath, convertRouteParamsToInfo, getContainerTypeFromPath, locToUrlString } from "../helperFunctions";
import { strings } from "../localization/localization.web";
import { ArgumentRole, ContainerType, ElementPath, FirestoreConstants, PathConstants, StatementRole, navigationProps } from "../typings/types";
import { LazyList } from "./FeedList";
import { PersonalOrArgumentOrTopicScreen } from "./StatementList";
import { ArgumentThreadHeader, LineToSideOfViewDesktop, LineToSideOfViewMobile, ParentViewDesktop, ParentViewMobile, StatementScreen } from "./StatementScreen";
import { NavigationProp, RouteProp, useFocusEffect } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import DraggableFlatList, {
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { Layout } from 'react-native-reanimated';
import { Colors, Text, View } from 'react-native-ui-lib';
import {
  useContainerListener,
} from '../dataModel';
import {
  convertPathToInfo,
  isEditingAllowed,
} from '../helperFunctions';
import { StanceType } from '../typings/types';
import { Container, Statement } from './Containers';
import { FloatingActionButton } from './FloatingActionButton';
import { useIsMobile, useIsTablet } from '../MediaQueries';
import { Ionicons } from '@expo/vector-icons';
import LinearGradient from 'react-native-linear-gradient';
import { THREAD_WIDTH } from '../styles';
import { NothingHereInformation } from './Alert';
import { Footer } from './Footer';
import { ScrollView } from 'react-native-gesture-handler';
import { doc, DocumentData, getDoc, getFirestore } from 'firebase/firestore';
import { useUserState } from '../firebaseWrapper/firebaseWrapper';

export function StatementOrArgumentOrPersonalOrTopicScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const info: ElementPath = convertRouteParamsToInfo(route.params) as ElementPath;

  if (info.id === FirestoreConstants.ROOT_ID || [ContainerType.CATEGORY, ContainerType.ARGUMENT].includes(info.type)) {
    return (
      <PersonalOrArgumentOrTopicScreen navigation={navigation} route={route} />
    );
  } else {
    return (
      <StatementScreen navigation={navigation} route={route} />
    );
  }
}

export function GroupStatementScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  let newRoute = { ...route };
  newRoute.params = { ...newRoute.params, loc: locToUrlString(PathConstants.GROUP) };
  return <StatementOrArgumentOrPersonalOrTopicScreen navigation={navigation} route={newRoute} />;
}

export function PublicStatementScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  let newRoute = { ...route };
  newRoute.params = { ...newRoute.params, loc: locToUrlString(PathConstants.PUBLIC) };
  return <StatementOrArgumentOrPersonalOrTopicScreen navigation={navigation} route={newRoute} />;
}

export function PersonalStatementScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  let newRoute = { ...route };
  newRoute.params = { ...newRoute.params, loc: locToUrlString(PathConstants.PERSONAL) };
  return <StatementOrArgumentOrPersonalOrTopicScreen navigation={navigation} route={newRoute} />;
}

type StatementViewListProps = {
  navigation: any;
  route: any;
  location: PathConstants;
  groupId: any;
  topicId: any;
  memberId: any;
  groupAdminLoggedIn: any;
};

export function StatementViewList({
  navigation,
  route,
  location,
  groupId,
  topicId,
  memberId,
  groupAdminLoggedIn,
}: StatementViewListProps): JSX.Element {

  return (
    <LazyList
      navigation={navigation}
      route={route}
      query={getGroupStatementsQuery(groupId, topicId, true)}
      location={location}
      groupId={groupId}
      memberId={memberId}
      groupAdminLoggedIn={groupAdminLoggedIn}
      header={strings.main_statements}
      showStatementView={true}
    />
  )
}

export function StatementView({
  navigation,
  route,
  location,
  statementId,
  memberId,
  groupId,
  groupAdminLoggedIn,
  role
}: StatementViewProps): JSX.Element {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const routeMemberId = route?.params?.member;
  const routeGroupId = route?.params?.groupId;
  const [group, setGroup] = React.useState<DocumentData>();
  const [adminLoggedIn, setAdminLoggedIn] = React.useState(false);
  const [user, loading] = useUserState();
  const [argumentsCollapsed, setArgumentsCollapsed] = useState(true);

  useEffect(() => {
    if (groupId !== undefined) {
      const documentReference = doc(getFirestore(), FirestoreConstants.GROUPS, groupId);
      getDoc(documentReference).then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          setGroup({ ...documentSnapshot.data(), id: documentSnapshot.id });
        } else {
          console.log('No such document!');
        }
      }).catch((error) => {
        console.log('Error getting document:', error);
      });
    }
  }, []);

  useEffect(() => {
    if (group && user) {
      setAdminLoggedIn(group.admin === user.uid);
    }
  }, [group, user]);

  return (
    <>
      {isMobile || isTablet ? (
        <StatementViewMobile
          navigation={navigation}
          route={route}
          location={location}
          statementId={statementId}
          type={ContainerType.STATEMENT}
          memberId={memberId || routeMemberId}
          groupId={groupId || routeGroupId}
          groupAdminLoggedIn={adminLoggedIn}
          argumentsCollapsed={argumentsCollapsed}
          setArgumentsCollapsed={setArgumentsCollapsed}
          role={role}
        />
      ) : (
        <StatementViewDesktop
          navigation={navigation}
          route={route}
          location={location}
          statementId={statementId}
          type={ContainerType.STATEMENT}
          memberId={memberId || routeMemberId}
          groupId={groupId || routeGroupId}
          groupAdminLoggedIn={adminLoggedIn}
          argumentsCollapsed={argumentsCollapsed}
          setArgumentsCollapsed={setArgumentsCollapsed}
          role={role}
        />
      )}
    </>
  );
}

export function StatementViewMobile({
  navigation,
  route,
  location,
  statementId,
  type,
  memberId,
  groupId,
  groupAdminLoggedIn,
  argumentsCollapsed = false,
  setArgumentsCollapsed,
  role
}: StatementViewProps): JSX.Element {
  const info: ElementPath = convertRouteParamsToInfo(route?.params, location, statementId, type, groupId) as ElementPath;

  const { container, loading, error } = useContainerListener(info);
  const [argumentsData, setArguments] = React.useState([
    { [convertInfoToPath(info)]: StanceType.NEUTRAL },
  ]);
  const [transitionFinished, setTransitionFinished] = useState(false);

  useFocusEffect(() => {
    if (!transitionFinished) {
      setTransitionFinished(true);
    }
  });

  useEffect(() => {
    if (container && transitionFinished) {
      setArguments([{ [convertInfoToPath(info)]: StanceType.NEUTRAL }, ...container.getChildData()]);
    }
  }, [container, transitionFinished]);

  if (error) {
    return (
      <View flex center>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  const onDragEnd = ({ data }) => {
    if (!isEditingAllowed(container)) {
      return;
    }
    container.setChildData(
      data.filter(item => Object.keys(item)[0] !== convertInfoToPath(info)),
    );
    setArguments([
      { [convertInfoToPath(info)]: StanceType.NEUTRAL },
      ...data.filter(item => Object.keys(item)[0] !== convertInfoToPath(info)),
    ]);
  };

  const renderArgument = ({ item, getIndex, drag, isActive }) => {
    if (getIndex() === 0) {
      return <>
        {argumentsData.length > 1 ? <ArgumentThreadHeader stance={StanceType.NEUTRAL} /> : null}
      </>;
    } else {
      return (
        <LineToSideOfViewMobile
          key={Object.keys(item)[0]}
          stance={item[Object.keys(item)[0]]}
          lastLine={getIndex() === argumentsData.length - 1 && !isActive}
          isActive={isActive}
        >
          <ScaleDecorator>
            {/* {getIndex() === 1 && !isActive ?
              <View marginL-20>
                <ThreadConnector stance={dataList[getIndex()][Object.keys(dataList[getIndex()])[0]]}/>
              </View>
              : null} */}
            <View marginH-0 marginL-0>
              <Container
                info={{ ...info, ...convertPathToInfo(Object.keys(item)[0]) }}
                parentInfo={info}
                navigation={navigation}
                drag={isEditingAllowed(container) ? drag : undefined}
                stance={item[Object.keys(item)[0]]}
                displayAsCondition={true}
                groupId={groupId}
                groupAdminLoggedIn={groupAdminLoggedIn}
                memberId={memberId}
                role={item[Object.keys(item)[0]] === StanceType.SUPPORT ? ArgumentRole.SUPPORTING : ArgumentRole.ATTACKING}
              />
              {/* {!(getIndex() === dataList.length - 1 && !isActive) ?
                <View style={{width: 5, zIndex: -100}}>
                  <ThreadConnector stance={dataList[getIndex()+1][Object.keys(dataList[getIndex()+1])[0]]}/>
                </View>
              : null} */}
            </View>
          </ScaleDecorator>
        </LineToSideOfViewMobile>
      );
    }
  };

  return (
    <>
      <ParentViewMobile
        navigation={navigation}
        route={route}
        location={location}
        statementId={statementId}
        type={type}
        groupId={'groupId' in info ? info.groupId : undefined}
        memberId={memberId}
        groupAdminLoggedIn={groupAdminLoggedIn}
        argumentsCollapsed={argumentsCollapsed}
        setArgumentsCollapsed={setArgumentsCollapsed}
        role={argumentsCollapsed || argumentsData.length <= 1 ? role : StatementRole.CONCLUSION}
      />
      {argumentsData.length > 1 && !argumentsCollapsed ?
        <DraggableFlatList
          showsVerticalScrollIndicator={false}
          dragItemOverflow={true}
          keyboardShouldPersistTaps="handled"
          data={argumentsData}
          onDragEnd={onDragEnd}
          containerStyle={{ flex: 1 }}
          itemLayoutAnimation={Layout}
          renderPlaceholder={() => (
            <LineToSideOfViewMobile stance={StanceType.NEUTRAL} lastLine={false} />
          )}
          refreshing={loading}
          keyExtractor={item => Object.keys(item)[0]}
          stickyHeaderIndices={[0]}
          stickyHeaderHiddenOnScroll={true}
          renderItem={renderArgument}
        />
        : null
      }
    </>
  );
}

export type StatementViewProps = {
  navigation: NavigationProp<any>;
  route?: RouteProp<any, any>;
  location?: string;
  statementId?: string;
  type?: string;
  memberId?: string;
  groupId?: string;
  groupAdminLoggedIn?: boolean;
  argumentsCollapsed?: boolean;
  setArgumentsCollapsed?: Function;
  role?: StatementRole;
}

export function StatementViewDesktop({
  navigation,
  route,
  location,
  statementId,
  type,
  memberId,
  groupId,
  groupAdminLoggedIn,
  argumentsCollapsed=false,
  setArgumentsCollapsed,
  role
}: StatementViewProps): JSX.Element {
  const info: ElementPath = convertRouteParamsToInfo(route?.params, location, statementId, type, groupId);

  const { container, loading, error } = useContainerListener(info);
  const [proList, setProList] = React.useState([]);
  const [conList, setConList] = React.useState([]);
  const [transitionFinished, setTransitionFinished] = useState(false);

  useFocusEffect(() => {
    if (!transitionFinished) {
      setTransitionFinished(true);
    }
  });

  useEffect(() => {
    if (container && transitionFinished) {
      setProList(container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.SUPPORT));
      setConList(container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.OPPOSE));
    }
  }, [container, transitionFinished]);

  if (error) {
    return (
      <View flex center>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  const onDragEndPro = ({ data: newList, from, to }) => {
    // TODO: Needs a different logic for desktop
    // if (!isEditingAllowed(container)) {
    //   return;
    // }

    // let childData = container.getChildData();

    // const from_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to])[0]);
    // let to_index = 0;
    // try {
    //   to_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to-1])[0]);
    // } catch (e) {
    //   to_index = 0;
    // }

    // const from_child = childData.splice(from, 1)[0];
    // childData.splice(to, 0, from_child);

    // setProList(newList);
    // container.setChildData(childData);
  };

  const onDragEndCon = ({ data: newList, from, to }) => {
    // TODO: Needs a different logic for desktop
    // if (!isEditingAllowed(container)) {
    //   return;
    // }

    // let childData = container.getChildData();

    // const from_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to])[0]);
    // let to_index = 0;
    // try {
    //   to_index = childData.findIndex(item => Object.keys(item)[0] === Object.keys(newList[to-1])[0]);
    // } catch (e) {
    //   to_index = 0;
    // }

    // const from_child = childData.splice(from, 1)[0];
    // childData.splice(to, 0, from_child);

    // setProList(newList);
    // container.setChildData(childData);
  };

  const renderProItem = ({ item, getIndex, drag, isActive }) => {
    const parentInfo = info;
    let itemInfo: any;
    if ('groupId' in parentInfo) {
      itemInfo = {
        loc: PathConstants.GROUP,
        id: Object.keys(item)[0],
        type: getContainerTypeFromPath(Object.keys(item)[0]),
        groupId: parentInfo.groupId
      };
    } else {
      itemInfo = convertPathToInfo(Object.keys(item)[0])
    }
    return (
      <LineToSideOfViewDesktop
        key={Object.keys(item)[0]}
        stance={StanceType.SUPPORT}
        lastLine={getIndex() === proList.length - 1 && !isActive}
        isActive={isActive}>
        <ScaleDecorator>
          <View marginH-0 marginL-0>
            <View style={{ borderLeftWidth: 5, borderColor: Colors.greenBG, height: 0 }} />
            <Container
              info={{ ...info, ...itemInfo }}
              parentInfo={info}
              navigation={navigation}
              drag={isEditingAllowed(container) ? drag : undefined}
              stance={item[Object.keys(item)[0]]}
              displayAsCondition={true}
              groupId={groupId}
              memberId={memberId}
              groupAdminLoggedIn={groupAdminLoggedIn}
              role={ArgumentRole.SUPPORTING}
            />
            {/* {!(getIndex() === proList.length - 1 && !isActive) ?
            <ThreadConnector stance={StanceType.SUPPORT}/>
            : null} */}
          </View>
        </ScaleDecorator>
      </LineToSideOfViewDesktop>
    );
  };

  const renderConItem = ({ item, getIndex, drag, isActive }) => {
    return (
      <LineToSideOfViewDesktop
        key={Object.keys(item)[0]}
        stance={StanceType.OPPOSE}
        lastLine={getIndex() === conList.length - 1 && !isActive}
        isActive={isActive}>
        <ScaleDecorator>
          <View marginH-0 marginL-0>
            <View style={{ borderLeftWidth: 5, borderColor: Colors.redBG, height: 0 }} />
            <Container
              info={{ ...info, ...convertPathToInfo(Object.keys(item)[0]) }}
              parentInfo={info}
              navigation={navigation}
              drag={isEditingAllowed(container) ? drag : undefined}
              stance={item[Object.keys(item)[0]]}
              displayAsCondition={true}
              groupId={groupId}
              memberId={memberId}
              groupAdminLoggedIn={groupAdminLoggedIn}
              role={ArgumentRole.ATTACKING}
            />
            {/* {!(getIndex() === conList.length - 1 && !isActive) ?
            <ThreadConnector stance={StanceType.OPPOSE}/>
            : null} */}
          </View>
        </ScaleDecorator>
      </LineToSideOfViewDesktop>
    );
  };

  return (
    <>
      <ParentViewDesktop
        navigation={navigation}
        route={route}
        location={location}
        statementId={statementId}
        type={type}
        groupId={'groupId' in info ? info.groupId : undefined}
        memberId={memberId}
        groupAdminLoggedIn={groupAdminLoggedIn}
        argumentsCollapsed={argumentsCollapsed}
        setArgumentsCollapsed={setArgumentsCollapsed}
        role={argumentsCollapsed || proList.length + conList.length <= 0 ? role : StatementRole.CONCLUSION}
      />
      {!argumentsCollapsed ? 
          <View flex row>
            {proList.length > 0 ?
              <DraggableFlatList
                showsVerticalScrollIndicator={false}
                dragItemOverflow={true}
                keyboardShouldPersistTaps="handled"
                data={proList}
                onDragEnd={onDragEndPro}
                containerStyle={{ flex: 1 }}
                itemLayoutAnimation={Layout}
                renderPlaceholder={() => (
                  <LineToSideOfViewDesktop stance={StanceType.SUPPORT} lastLine={false} />
                )}
                refreshing={loading}
                keyExtractor={item => Object.keys(item)[0]}
                renderItem={renderProItem}
                ListHeaderComponent={container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.SUPPORT).length > 0 ?
                  <ArgumentThreadHeader
                    stance={StanceType.SUPPORT}
                  />
                  : null
                }
                stickyHeaderIndices={[0]}
              />
              : null
            }
            {conList.length > 0 ?
              <DraggableFlatList
                showsVerticalScrollIndicator={false}
                dragItemOverflow={true}
                keyboardShouldPersistTaps="handled"
                data={conList}
                onDragEnd={onDragEndCon}
                containerStyle={{ flex: 1 }}
                itemLayoutAnimation={Layout}
                renderPlaceholder={() => (
                  <LineToSideOfViewDesktop stance={StanceType.OPPOSE} lastLine={false} />
                )}
                refreshing={loading}
                keyExtractor={item => Object.keys(item)[0]}
                renderItem={renderConItem}
                ListHeaderComponent={container.getChildData().filter(item => item[Object.keys(item)[0]] === StanceType.OPPOSE).length > 0 ?
                  <ArgumentThreadHeader
                    stance={StanceType.OPPOSE}
                  />
                  : null
                }
                stickyHeaderIndices={[0]}
              />
              : null
            }
          </View>
        : null}
    </>
  );
}