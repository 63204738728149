import { Pressable, StatusBar } from 'react-native';
import { Colors, View } from 'react-native-ui-lib';
import { navigationProps } from '../typings/types';
import LogoIcon from '../../assets/res/LogoLM.svg';
import React from 'react';
import { strings } from '../localization/localization.web';
import { MaterialIcons } from '@expo/vector-icons';
import { NotYetImplementedDialog } from './Dialogs';
import { HelpButton, LogInButton } from './Buttons';
import { getAuth } from 'firebase/auth';

/**
 * Set the options for displaying the header.
 *
 * @param navigationProps Not yet used. Must be set if profile icon shall be shown and navigatable.
 * @returns Options for displaying the header.
 */
export function Header(
  navigationProps: navigationProps | undefined = undefined,
): {} {
  const user = getAuth().currentUser;
  // Set status bar to same style as header.
  StatusBar.setBarStyle('dark-content');
  StatusBar.setBackgroundColor(Colors.primaryBG);
  // StatusBar.setTranslucent(true);

  return {
    headerMode: 'float',
    title: 'LucidMind',
    headerTitle: () => {
      return <LogoIcon height={32} width={32} />;
    },
    headerTitleAlign: 'center',
    headerStyle: {
      backgroundColor: Colors.primaryBG,
      borderBottomWidth: 0,
    },
    headerRight: () =>
      <View row centerV marginR-20>
        <Pressable onPress={() => NotYetImplementedDialog(strings.search, strings)}>
          <MaterialIcons name="search" size={30} color={Colors.iconSecondary} />
        </Pressable>
        <View marginL-5>
          <HelpButton />
        </View>
        {user.isAnonymous ?
          <View marginL-5>
            <LogInButton onPress={() => navigationProps.navigation.navigate('login')} />
          </View> : null
        }
      </View>
    // headerRight: () => {
    //     if(navigationProps && navigationProps.navigation) {
    //         let content = <ProfileIcon height={30} width={30} style={{color: 'white', margin: 5}}/>
    //         if(user) {
    //             if(user.photoURL) {
    //                 content = <Card.Image height={30} width={30} style={{borderRadius: 30, margin: 5}} source={{uri: user.photoURL}}/>
    //             } else {
    //                 if(user.displayName) {
    //                     var lettersToDisplay = "";
    //                     if(user.displayName.split(" ", 2).length > 1) {
    //                         lettersToDisplay = user.displayName.split(" ")[0].substring(0, 1) + user.displayName.split(" ")[1].substring(0, 1);
    //                     } else {
    //                         lettersToDisplay = user.displayName.substring(0, 2);
    //                     }
    //                     content = <Text white margin-8 center text70 bold height={25} width={25}>{lettersToDisplay}</Text>
    //                 }
    //             }
    //         }
    //         return (
    //             <TouchableOpacity bg-logo height={45} width={45} style={{borderRadius: 30}} onPress={() => navigationProps.navigation.navigate('Profile')}>
    //                 {content}
    //             </TouchableOpacity>
    //         );
    //     } else {
    //         return null;
    //     }
    // },
  };
}