import { Colors, Icon, Text, View } from 'react-native-ui-lib';
import {
  convertInfoToPath,
  convertRouteParamsToInfo,
  createPersonalPathFromId,
  getContainerTypeFromPath,
  isEditingAllowed,
} from '../helperFunctions';
import { ArgumentCard, Category, Container, Statement } from './Containers';
import { FloatingActionButton } from './FloatingActionButton';
import {
  useCategoryListener,
  useContainerListener,
} from '../dataModel';
import { FirestoreConstants, dbPath, navigationProps, elementId, ElementPath, ContainerType, StanceType, PathConstants } from '../typings/types';
import { FlatList, Pressable, ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import {
  useFocusEffect,
  useNavigation,
  useScrollToTop,
} from '@react-navigation/native';
import DraggableFlatList, {
  ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { Layout } from 'react-native-reanimated';
import { useIsMobile, useIsTablet } from '../MediaQueries';
import { styles } from '../styles';
import { strings } from '../localization/localization.web';
import LinearGradient from 'react-native-linear-gradient';
import MasonryList from '@react-native-seoul/masonry-list';
import { Dropdown } from './Dropdown';
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { NothingHereInformation } from './Alert';
import { ButtonPrimary } from './Buttons';
import { NotYetImplementedDialog } from './Dialogs';
import { Footer } from './Footer';
import { StatementScreen } from './StatementScreen';
import { StatementView } from './StatementView';
import { getPersonalStatementsQuery, getPersonalTopics, getPersonalTopicsQuery, getUserId } from '../firebaseWrapper/firebaseWrapper';
import { LazyList } from './FeedList';
import { onSnapshot } from 'firebase/firestore';

export function PersonalOrArgumentOrTopicScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  const parentInfo: ElementPath = convertRouteParamsToInfo(route.params) as ElementPath;
  const { container, loading, error } = useContainerListener(parentInfo);
  const [topicList, setTopicList] = useState<ElementPath[]>([]);
  const [topicsLoading, setTopicsLoading] = React.useState(true);
  const [statementList, setStatementList] = useState<ElementPath[]>([]);
  const [transitionFinished, setTransitionFinished] = useState(false);
  const [conclusionInfo, setConclusionInfo] = useState<ElementPath>(undefined);
  const isMobile = useIsMobile();
  // const [activeView, setActiveView] = useState(0);
  // const activeViewOptions = [strings.published, strings.bookmark];

  useFocusEffect(() => {
    if (!transitionFinished) {
      setTransitionFinished(true);
    }
  });

  if (error) {
    return (
      <View flex center>
        <Text>Error: {error.message}</Text>
      </View>
    );
  }

  // Reverse list if we are at the root level of the personal tree.
  // Results in the newest statements being displayed first.
  useEffect(() => {
    if (container && transitionFinished && route.params.id !== FirestoreConstants.ROOT_ID) {
      const list = container.getChildInfos();
      if (
        convertInfoToPath(parentInfo) === FirestoreConstants.ROOT_PATH ||
        parentInfo.type === ContainerType.CATEGORY
      ) {
        list.reverse();
      }
      setStatementList(list.filter((info) => info.type === ContainerType.STATEMENT));
      setTopicList(list.filter((info) => info.type === ContainerType.CATEGORY));
    }
  }, [container, transitionFinished]);

  useEffect(() => {
    if(route.params.id === FirestoreConstants.ROOT_ID) {
      getPersonalTopics().then((topics) => {
        setTopicList(
          topics.map((topic: any) => ({
            loc: PathConstants.PERSONAL,
            userId: topic.userId,
            id: topic.id,
            type: topic.type,
          }))
        );
        setTopicsLoading(false);
      })
    }
  }, []);

  onSnapshot(getPersonalTopicsQuery(), (personalTopicsSnapshot) => {
      if (!topicsLoading && route.params.id === FirestoreConstants.ROOT_ID) {
        setTopicsLoading(true);
        if (personalTopicsSnapshot.docs.length !== topicList.length) {
          setTopicList(
            personalTopicsSnapshot.docs
              .map((document) => ({ ...document.data(), id: document.id }))
              .map((topic: any) => ({
                loc: PathConstants.PERSONAL,
                userId: topic.userId,
                id: topic.id,
                type: topic.type,
              }))
          )
          setTopicsLoading(false);
        }
      }
    });

  useEffect(() => {
    if (parentInfo.type === ContainerType.ARGUMENT && container && transitionFinished) {
      setConclusionInfo(container.getParentInfos()[0]);
    }
  }, [container, transitionFinished]);

  return (
    <View
      flex
      style={{
        backgroundColor: Colors.primaryBG
      }}
    >
      <View flex
        style={{
          backgroundColor: isMobile ? 'transparent' : Colors.secondaryBG,
          borderRadius: 20,
          marginHorizontal: isMobile ? 0 : 5,
          marginVertical: 5,
          overflow: 'hidden',
        }}
      >
        {parentInfo.type === ContainerType.ARGUMENT && container && conclusionInfo ?
          <View flex style={{ backgroundColor: 'transparent' }}>
            <View style={{
              flex: 1,
              backgroundColor: Colors.secondaryBG,
              borderRadius: 20,
              margin: 0,
              marginHorizontal: isMobile ? 0 : 20,
              marginBottom: 0,
              // overflow: 'hidden',
              paddingTop: isMobile ? 10 : 20,
              paddingHorizontal: isMobile ? 10 : 0
            }}>
              <ScrollView showsVerticalScrollIndicator={false}>
                <ArgumentCard navigation={navigation} info={parentInfo} argument={container} />
                <View marginL-20 marginT-10 marginR-10 marginB-60>
                  <ButtonPrimary label={strings.critisize} onPress={() => NotYetImplementedDialog(strings.critisize, strings)} />
                </View>
              </ScrollView>
            </View>
          </View>
          :
          <View flex>
            <View style={{ marginLeft: isMobile ? 10 : 25, marginTop: isMobile ? 0 : 20 }}>
              <TopicList
                infoList={topicList}
                parentInfo={parentInfo}
                navigation={navigation}
                loading={loading}
              />
            </View>
            {/* <Dropdown activeIndex={activeView} setActiveIndex={setActiveView} options={activeViewOptions} /> */}
            {!loading && !error && transitionFinished ?
              <LazyList
                navigation={navigation}
                route={route}
                query={getPersonalStatementsQuery(parentInfo.type === ContainerType.CATEGORY ? createPersonalPathFromId(parentInfo.id) : undefined)}
                location={PathConstants.PERSONAL}
                memberId={undefined}
                header={route.params.id === FirestoreConstants.ROOT_ID ? strings.all_statements : strings.main_statements}
                showStatementView={true}
              />
              :
              <NothingHereInformation text={strings.no_statements_info} />
            }
          </View>}
        <FloatingActionButton
          navigation={navigation}
          parentInfo={parentInfo}
          possibleTypes={
            convertInfoToPath(parentInfo) === FirestoreConstants.ROOT_PATH ||
              parentInfo.type === ContainerType.CATEGORY
              ? [ContainerType.CATEGORY, ContainerType.STATEMENT]
              : []
          }
        />
      </View>
    </View>
  );
}

type ListProps = {
  infoList: ElementPath[];
  parentInfo: ElementPath;
  navigation: any;
  loading?: boolean;
  onRefresh?: () => void;
  onEndReached?: () => void;
  header?: string;
  groupId?: string;
  memberId?: string;
  groupAdminLoggedIn?: boolean;
  showStatementView?: boolean;
};

export function List({ infoList, navigation, parentInfo, loading = false, onEndReached, onRefresh, header, groupId, memberId, groupAdminLoggedIn, showStatementView = false }: ListProps): JSX.Element {
  const [infoListLocal, setInfoList] = useState(infoList);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [activeListType, setActiveListType] = useState('list');
  const listTypeOptions = ['list', 'masonry'];

  const { container } = useContainerListener(parentInfo);

  useEffect(() => {
    setInfoList(infoList);
  }, [infoList]);

  const ref = React.useRef<FlatList<elementId>>(null);
  // TODO: Not sure if this is actually working.
  useScrollToTop(ref);

  const onDragEnd = ({ data: newInfoList, from, to }) => {
    // TODO: Whatch out for access to other users' root containers.
    // For now, editing of root list not allowed.
    if (!isEditingAllowed(container)) {
      return;
    }

    const dataList = [...container.getChildData()];
    // Reverse list if we are at the root level of the personal tree or a category.
    if (
      convertInfoToPath(parentInfo) === FirestoreConstants.ROOT_PATH ||
      parentInfo.type === ContainerType.CATEGORY || parentInfo.type === ContainerType.GROUP
    ) {
      from = dataList.length - from - 1;
      to = dataList.length - to - 1;
    }
    const from_child = dataList.splice(from, 1)[0];
    dataList.splice(to, 0, from_child);

    container.setChildData(dataList);
    setInfoList(newInfoList);
  };

  const renderItem = ({ item: info }) => {
    return (
      info && (info as { type: ContainerType }).type === ContainerType.STATEMENT ?
        <View style={{ marginBottom: 10, marginHorizontal: isMobile ? 0 : 5 }}>
          {showStatementView ?
            <StatementView
              navigation={navigation}
              location={info.loc}
              statementId={info.id}
              groupId={groupId}
              groupAdminLoggedIn={groupAdminLoggedIn}
              memberId={memberId}
            />
            :
            <Statement
              info={info as ElementPath}
              parentInfo={parentInfo}
              navigation={navigation}
              drag={undefined}
              groupId={groupId}
              memberId={memberId}
              groupAdminLoggedIn={groupAdminLoggedIn}
            />
          }
        </View>
        : <></>
    );
  };

  return (
    <View flex style={{ backgroundColor: 'transparent' }}>
      <View style={{
        flex: 1,
        backgroundColor: Colors.secondaryBG,
        borderRadius: 20,
        margin: 0,
        marginHorizontal: isMobile ? 0 : 20,
        marginBottom: 0,
        overflow: 'hidden',
        paddingTop: isMobile ? 10 : 0,
        paddingHorizontal: isMobile ? 10 : 0
      }}>
        <MasonryList
          ListEmptyComponent={() => <NothingHereInformation text={strings.no_statements_info} />}
          data={infoList}
          keyExtractor={(info: ElementPath) => info.id}
          numColumns={isTablet || isMobile || activeListType === 'list' ? 1 : 2}
          showsVerticalScrollIndicator={false}
          onEndReached={() => {
            if (!loading && onEndReached) {
              onEndReached();
            }
          }}
          onEndReachedThreshold={0.5}
          onRefresh={() => {
            if (onRefresh) {
              onRefresh();
            }
          }}
          refreshing={loading}
          renderItem={renderItem}
          ListFooterComponent={infoList.length > 1 ? <Footer navigation={navigation} /> : null}
          ListHeaderComponent={!isMobile || header ?
            <View row style={{ justifyContent: header ? 'space-between' : 'flex-end', marginRight: 10, marginLeft: 5, marginBottom: header ? 10 : 5 }}>
              {header ?
                <Text textPrimary text60>
                  {header}
                </Text>
                : null
              }
              {!isMobile ?
                <Pressable onPress={() => {
                  const activeIndex = listTypeOptions.indexOf(activeListType);
                  const nextIndex = activeIndex === listTypeOptions.length - 1 ? 0 : activeIndex + 1;
                  setActiveListType(listTypeOptions[nextIndex]);
                }}
                >
                  {activeListType === 'masonry' ?
                    <MaterialIcons name="view-headline" size={20} color={Colors.iconSecondary} />
                    :
                    <MaterialCommunityIcons name="view-dashboard-outline" size={20} color={Colors.iconSecondary} />
                  }
                </Pressable>
                :
                null
              }
            </View>
            : null
          }
        />
        {/* <DraggableFlatList
          showsVerticalScrollIndicator={false}
          // dragItemOverflow={true}
          keyboardShouldPersistTaps="handled"
          ref={ref}
          data={infoList}
          onDragEnd={onDragEnd}
          containerStyle={{ flex: 1 }}
          itemLayoutAnimation={Layout}
          onEndReached={() => {
            if (!loading && onEndReached) {
              onEndReached();
            }
          }}
          onEndReachedThreshold={0.5}
          onRefresh={() => {
            if (onRefresh) {
              onRefresh();
            }
          }}
          refreshing={loading}
          renderItem={renderItem}
          keyExtractor={(info: ElementPath) => info.id}
          ListFooterComponent={() => <View style={{ height: 75 }} />}
          initialNumToRender={infoList.length}
        /> */}
      </View>
    </View>
  );
}

type TopicListProps = {
  infoList: ElementPath[];
  parentInfo: ElementPath;
  navigation: any;
  loading?: boolean;
  onRefresh?: () => void;
  onEndReached?: () => void;
};

export function TopicListPublic(): JSX.Element {

  const isMobile = useIsMobile();

  return (
    <View style={{
      backgroundColor: isMobile ? Colors.primaryBG : undefined,
      overflow: 'hidden',
    }}>
      <View row style={{ marginLeft: 0 }}>
        <View key={"all-topics"} style={{ marginVertical: 10 }}>
          <Pressable
            style={[
              {
                elevation: 3,
              },
            ]}
          // onPress={() => { navigation.push();}}
          >
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              colors={[Colors.backgroundAccent, Colors.backgroundAccent2]}
              style={[styles.borderPrimary, { width: 'auto' }]}
            >
              <View padding-2 style={[
                {
                  elevation: 3,
                },
                styles.borderPrimary,
              ]}>
                <View row flexS paddingH-10 centerV>
                  <View flex row centerV>
                    <Text h5 marginV-5 style={styles.textAccentSecondary}>
                      {strings.all_topics}
                    </Text>
                  </View>
                </View>
              </View>
            </LinearGradient>
          </Pressable>
        </View>
      </View>
    </View>
  )
}

export function TopicList(props: TopicListProps): JSX.Element {
  const [infoList, setInfoList] = useState(props.infoList);
  const parentInfo = props.parentInfo;
  const navigation = props.navigation;
  const loading = props.loading || false;
  const onRefresh = props.onRefresh;
  const onEndReached = props.onEndReached;
  const isMobile = useIsMobile();

  const { container } = useContainerListener(parentInfo);
  const { category } = parentInfo.type === ContainerType.CATEGORY ? useCategoryListener(parentInfo) : { category: undefined };

  useEffect(() => {
    setInfoList(props.infoList);
  }, [props.infoList]);

  const ref = React.useRef<FlatList<elementId>>(null);
  // TODO: Not sure if this is actually working.
  useScrollToTop(ref);

  const onDragEnd = ({ data: newInfoList, from, to }) => {
    // TODO: Whatch out for access to other users' root containers.
    // For now, editing of root list not allowed.
    if (!isEditingAllowed(container)) {
      return;
    }

    const dataList = [...container.getChildData()];
    // Reverse list if we are at the root level of the personal tree or a category.
    if (
      convertInfoToPath(parentInfo) === FirestoreConstants.ROOT_PATH ||
      parentInfo.type === ContainerType.CATEGORY
    ) {
      from = dataList.length - from - 1;
      to = dataList.length - to - 1;
    }
    const from_child = dataList.splice(from, 1)[0];
    dataList.splice(to, 0, from_child);

    container.setChildData(dataList);
    setInfoList(newInfoList);
  };

  const renderItem = ({ item: info, drag }) => {
    if (info.type !== ContainerType.CATEGORY) {
      return <View key={info.id} />;
    }
    return (
      <ScaleDecorator>
        <View key={info.id} style={{ marginVertical: 10, marginHorizontal: 5, marginRight: 0 }}>
          <Category
            info={info}
            parentInfo={parentInfo}
            navigation={navigation}
            drag={isEditingAllowed(container) ? drag : undefined}
            active={false}
            showOptions={false}
          />
        </View>
      </ScaleDecorator>
    );
  };

  return (
    <View style={{
      backgroundColor: isMobile ? Colors.primaryBG : undefined,
      overflow: 'hidden',
    }}>
      <View style={{
        backgroundColor: isMobile ? Colors.primaryBG : undefined,
        overflow: 'hidden',
      }}>
        <DraggableFlatList
          showsHorizontalScrollIndicator={false}
          // dragItemOverflow={true}
          horizontal={true}
          keyboardShouldPersistTaps="handled"
          ref={ref}
          data={infoList}
          onDragEnd={onDragEnd}
          itemLayoutAnimation={Layout}
          onEndReached={() => {
            if (!loading && onEndReached) {
              onEndReached();
            }
          }}
          onEndReachedThreshold={0.5}
          onRefresh={() => {
            if (onRefresh) {
              onRefresh();
            }
          }}
          refreshing={loading}
          renderItem={renderItem}
          keyExtractor={(item: ElementPath) => item.id}
          ListFooterComponent={() => <View style={{ width: 10 }} />}
          ListHeaderComponent={() =>
            convertInfoToPath(parentInfo) !== FirestoreConstants.ROOT_PATH ?
              <View style={{ marginVertical: 10, marginHorizontal: 0, marginRight: 0 }}>
                <Category
                  info={parentInfo}
                  parentInfo={undefined}
                  navigation={navigation}
                  drag={undefined}
                  active={true}
                  showOptions={true}
                />
              </View>
              :
              <>
                <View key={"no-topic"} style={{ marginVertical: 10 }}>
                  <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    colors={[Colors.backgroundAccent, Colors.backgroundAccent2]}
                    style={[styles.borderPrimary, { width: 'auto' }]}
                  >
                    <Pressable
                      style={[
                        {
                          elevation: 3,
                        },
                        styles.borderPrimary,
                      ]}
                      onPress={() => {
                        // navigation.push();
                      }}>
                      <View padding-2 style={[
                        {
                          elevation: 3,
                        },
                        styles.borderPrimary,
                      ]}>
                        <View row flexS paddingH-10 centerV>
                          <View flex row centerV>
                            <Text h5 marginV-5 style={styles.textAccentSecondary}>
                              {convertInfoToPath(parentInfo) !== FirestoreConstants.ROOT_PATH ? category?.getName() : strings.without_topic}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </Pressable>
                  </LinearGradient>
                </View>
              </>}
          initialNumToRender={infoList.length}
        />
      </View>
    </View>
  );
}
