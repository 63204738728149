import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import React from 'react';
import { applyOnPlatform } from '../helperFunctions';
import { dbPath } from '../typings/types';
import { DeepLinkingEntryScreen } from './DeepLinking';
import { CreateDialog, EditDialog } from './Dialogs';
import { Header } from './Header';
import { GroupStatementScreen } from './StatementView';
import { GroupsScreen } from './GroupsScreen';
import { GroupScreen } from './GroupScreen';
import { TopicScreen } from './TopicScreen';
import { ArgumentScreen } from './ArgumentScreen';

type FeedStackParamList = {
  feed: undefined;
  groups: undefined;
  StatementView: { path: dbPath };
  SourceScreen: { path: dbPath };
  CreateDialog: undefined;
  DeepLinkingEntryScreen: undefined;
  DeepLinkingEntryScreenPersonal: undefined;
  DeepLinkingEntryScreenPublic: undefined;
  DeepLinkingEntryScreenGroup: undefined;
  DeepLinkingEntryScreenGroupInvite: undefined;
  DeepLinkingEntryScreenUser: undefined;
};

export function GroupsMainScreen(): JSX.Element {
  const Stack = createStackNavigator<FeedStackParamList>();

  return (
    <Stack.Navigator
      initialRouteName="groups"
      screenOptions={navigationProps => Header(navigationProps)}
    >
      <Stack.Screen
        name="groups"
        component={GroupsScreen}
      />
      <Stack.Screen
        name="group"
        component={GroupScreen}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="GroupStatementView"
        component={GroupStatementScreen}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="argument"
        component={ArgumentScreen}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="topic"
        component={TopicScreen}
        options={applyOnPlatform({}, {
          ...TransitionPresets.SlideFromRightIOS,
        })}
      />
      <Stack.Screen
        name="CreateDialog"
        component={CreateDialog}
        options={{
          presentation: 'transparentModal',
          headerLeft: () => undefined,
          ...TransitionPresets.ModalFadeTransition,
        }}
      />
      <Stack.Screen
        name="EditDialog"
        component={EditDialog}
        options={{
          presentation: 'transparentModal',
          headerLeft: () => undefined,
          ...TransitionPresets.ModalFadeTransition,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenPublic"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenPersonal"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenGroup"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenGroupInvite"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
      <Stack.Screen
        name="DeepLinkingEntryScreenUser"
        component={DeepLinkingEntryScreen}
        options={{
          animationEnabled: false,
        }}
      />
    </Stack.Navigator>
  );
}