import React from 'react';
import { Text, View } from 'react-native-ui-lib';
import {
  createPublicPathFromId
} from '../helperFunctions';
import { navigationProps, userId, elementId, ContainerType } from '../typings/types';
import { StatementOrArgumentOrPersonalOrTopicScreen } from './StatementView';

export function DeepLinkingEntryScreen({
  navigation,
  route,
}: navigationProps): JSX.Element {
  let elementType: ContainerType = route.params?.elementType || null;
  const elementId: elementId = route.params?.elementId || null;
  const userId: userId = route.params?.userId || null;
  const groupId: elementId = route.params?.groupId || null;
  const inviteToken: string = route.params?.inviteToken || null;

  if (elementType != null) {
    elementType = stringTypeToNumberType(elementType);
  }

  const text = 'invalid link';
  if (userId != null) {
    if (elementType != null && elementId != null) {
      // text = `PERSONAL: userId: ${userId}, elementType: ${elementType}, elementId: ${elementId}`;
    } else {
      // text = `PROFILE: userId: ${userId}`;
    }
  } else if (groupId != null) {
    if (elementType && elementId) {
      // text = `GROUP: groupId: ${groupId}, elementType: ${elementType}, elementId: ${elementId}`;
    } else if (inviteToken != null) {
      // text = `GROUPINVITE: groupId: ${groupId}, inviteToken: ${inviteToken}`;
    }
  } else if (elementType != null && elementId != null) {
    // text = `PUBLIC: elementType: ${elementType}, elementId: ${elementId}`;
    const newParams = {
      path: createPublicPathFromId(`${elementType}-${elementId}`),
    };
    const newRoute = {
      params: newParams,
      key: route.key,
      name: route.name,
      path: route.path,
    };
    return <StatementOrArgumentOrPersonalOrTopicScreen navigation={navigation} route={newRoute} />;
  }

  return (
    <View flex center>
      <Text>{text}</Text>
    </View>
  );
}

function stringTypeToNumberType(type: string): ContainerType {
  switch (type) {
    case ContainerType.STATEMENT:
    case ContainerType.STATEMENT.toString():
    case 'statement':
      return ContainerType.STATEMENT;
    case ContainerType.SOURCE:
    case ContainerType.SOURCE.toString():
    case 'source':
      return ContainerType.SOURCE;
    case ContainerType.CATEGORY:
    case ContainerType.CATEGORY.toString():
    case 'category':
      return ContainerType.CATEGORY;
    case ContainerType.ARGUMENT:
    case ContainerType.ARGUMENT.toString():
    case 'statement_group':
      return ContainerType.ARGUMENT;
    case 'argument':
      return ContainerType.ARGUMENT;
    default:
      return ContainerType.NONE;
  }
}
