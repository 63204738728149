import { Alert, Platform, Pressable } from "react-native";
import { View, Text, Colors } from "react-native-ui-lib";
import Swal from 'sweetalert2';
import { Image } from 'react-native';
import SadNietzsche from '../../assets/res/sadNietzscheCropped.png';
import SadSocrates from '../../assets/res/sadSocratesCropped.png';
import { strings } from "../localization/localization.web";
import { useIsMobile } from "../MediaQueries";
import { styles } from "../styles";
import React from "react";
import ExclamationMarkIcon from '../../assets/res/exclamationMark.svg';
import CrossIcon from '../../assets/res/cross.svg';
import CheckmarkIcon from '../../assets/res/checkmark.svg';

export function alert(title: string, message?: string, cancelButtonText?: string, cancelButtonAction?: Function, confirmButtonText?: string, confirmButtonAction?: Function): any {
  if (Platform.OS === 'web') {
    const content = !message ? title : message;
    const result = window.confirm([content].filter(Boolean).join('\n'))
    if (result && confirmButtonAction) {
      confirmButtonAction();
    }
    // Swal.fire({
    //   titleText: title,
    //   text: message,
    //   showCancelButton: cancelButtonText !== undefined,
    //   cancelButtonText: cancelButtonText,
    //   showConfirmButton: confirmButtonText !== undefined,
    //   confirmButtonText: confirmButtonText,
    //   reverseButtons: true,
    //   customClass: {
    //     container: "class-container",
    //   }
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     confirmButtonAction();
    //   } else {
    //     cancelButtonAction();
    //   }
    // });
  } else {
    return Alert.alert(title, message, [
      { text: cancelButtonText, onPress: () => cancelButtonAction(), style: 'cancel' },
      { text: confirmButtonText, onPress: () => confirmButtonAction() },
    ]);
  }
}

export function LogInForFeatureAlert(): any {
  return alert(strings.login_to_use_this_feature);
}

export function NothingHereInformation({ text }: { text: string }): JSX.Element {

  const isMobile = useIsMobile();

  const options = [
    {
      label: strings.nothing_here,
      image: SadSocrates,
    },
    // {
    //   label: strings.nothing_here_funny_socrates,
    //   image: SadSocrates,
    // },
    // {
    //   label: strings.nothing_here_funny_nietzsche,
    //   image: SadNietzsche,
    // }
  ];

  const randomOption = options[Math.floor(Math.random() * options.length)];

  return (
    <View flex centerH style={{ justifyContent: isMobile ? 'flex-start' : 'center', marginTop: isMobile ? 20 : 0, padding: 20 }}>
      <Image source={randomOption.image} style={{
        width: isMobile ? 200 : 350,
        height: isMobile ? 200 : 350,
        borderRadius: 20, opacity: 1,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        padding: 20
      }} />
      <Text h2 textPrimary marginT-10 center>
        {randomOption.label}
      </Text>
      <Text h5 textSecondary center>
        {text}
      </Text>
    </View>
  );
}

export function Callout({ header, text, severity, handleAction }: { header?: string, text: string, severity?: "none" | "low" | "medium" | "high", handleAction?: Function }): JSX.Element {

  const getStyle = () => {
    switch (severity) {
      case "high":
        return [styles.conflictAlert, styles.conflictAlertCritical];
        break;
      case "medium":
        return [styles.conflictAlert, styles.conflictAlertMajor];
        break;
      case "low":
        return [styles.conflictAlert, styles.conflictAlertMinor];
        break;
      default:
        return [styles.conflictAlert, styles.conflictAlertNoConflict];
        break;
    }
  }

  const getIcon = () => {
    switch (severity) {
      case "high":
        return (
          <CrossIcon style={{ paddingRight: 5 }} fill={Colors.redFG} />
        );
      case "medium":
        return (
          <CrossIcon style={{ paddingRight: 5 }} fill={Colors.redFG} />
        );
      case "low":
        return (
          <ExclamationMarkIcon style={{ paddingRight: 5 }} fill={Colors.yellowFG} />
        );
      default:
        return (
          <CheckmarkIcon style={{ paddingRight: 5 }} fill={Colors.greenFG} />
        );
    }
  }

  const getResolveButton = () => {
    if (handleAction) {
      switch (severity) {
        case 'high':
          return (
            <Pressable
              style={[styles.resolveButton, styles.resolveButtonCritical]}
              onPress={() => handleAction()}
            >
              <Text style={styles.buttonSecondaryText}>
                {strings.resolve_conflict}
              </Text>
            </Pressable>
          );
        case 'medium':
          return (
            <Pressable style={[styles.resolveButton, styles.resolveButtonMajor]}>
              <Text style={styles.buttonSecondaryText}>
                {strings.resolve_conflict}
              </Text>
            </Pressable>
          );
        case 'low':
          return (
            <Pressable style={[styles.resolveButton, styles.resolveButtonMinor]}>
              <Text style={styles.buttonSecondaryText}>
                {strings.resolve_conflict}
              </Text>
            </Pressable>
          );
        default:
          return (<></>);
      }
    }
  }

  return (
    <View row style={[getStyle(), { justifyContent: "space-between", alignItems: "center" }]}>
      {header ? 
        <View flex>
          <View row style={{ alignItems: "center" }}>
            <View style={{ padding: 2 }}>
              {getIcon()}
            </View>
            {header ? <Text style={{ fontWeight: '600' }}>{header}</Text> : null}
          </View>
          <Text>
            {text}
          </Text>
        </View>
        : 
        <>
          <View style={{ padding: 2 }}>
            {getIcon()}
          </View>
          <Text>{text}</Text>
        </>
      }
      <View padding-3>
        {getResolveButton()}
      </View>
    </View>
  );
}
