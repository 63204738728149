import { NavigationProp, RouteProp } from '@react-navigation/native';

export type elementId = string;
export type userId = string;
export type groupId = string;
export type dbPath = string;

export enum ContainerType {
  NONE = '0',
  STATEMENT = '1',
  SOURCE = '2',
  ARGUMENT = '3',
  CATEGORY = '4',
  GROUP = '5',
}

export enum StanceType {
  NEUTRAL = 0,
  SUPPORT = 1,
  OPPOSE = 2,
}

export enum VoteType {
  UPVOTE = 1,
  DOWNVOTE = -1,
  NOVOTE = 0,
}
export enum StatementRole {
  CONCLUSION = 'conclusion',
  PREMISE = 'premise'
}

export enum ArgumentRole {
  SUPPORTING = 'supporting',
  ATTACKING = 'attacking'
}

export enum PathConstants {
  PERSONAL = '0',
  PUBLIC = '1',
  GROUP = '2',
}

export enum FirestoreConstants {
  NO_PATH = 'NO_PATH',
  USER_DATA = 'userData',
  DATA = 'data',
  PERSONAL_ELEMENTS = 'personalElements',
  PERSONAL_ROOT_ELEMENTS = 'rootElements',
  ROOT_ID = 'rootId',
  ROOT_PATH = PathConstants.PERSONAL + '_' + ROOT_ID,
  PUBLIC_DATA = 'publicData',
  FEED = 'testFeed',
  FEED_ELEMENTS = 'feedElements',
  FEED_ROOT_ELEMENTS = 'rootElements',
  PUBLIC_USER_DATA = 'public',
  PRIVATE_USER_DATA = 'private',
  BOOKMARKS = 'bookmarks',
  MAINTAINERS = 'maintainers',
  TUTORIAL_SLIDES_PATH = 'publicData/tutorials/introductorySlides',
  GROUPS = 'groups',
  STATEMENTS = 'statements',
  ARGUMENTS = 'arguments',
  TOPICS = 'topics',
  MEMBERS = 'members',
  REFERENCES = 'references',
}

const PersonalRootInfo = {
  loc: PathConstants.PERSONAL,
  id: FirestoreConstants.ROOT_ID,
  type: ContainerType.ARGUMENT,
};

export type navigationProps = {
  navigation: any;
  route: RouteProp<any, any>;
};

export type PublicPath = {
  loc: PathConstants.PUBLIC;
  id: elementId;
  type: ContainerType;
};

export type PersonalPath = {
  loc: PathConstants.PERSONAL;
  userId: userId;
  id: elementId;
  type: ContainerType;
};

export type GroupPath = {
  loc: PathConstants.GROUP;
  groupId: groupId;
  id: elementId;
  type: ContainerType;
};

export type ElementPath = PublicPath | PersonalPath | GroupPath;

export enum ConflictSeverity {
  NONE = 0,
  CRITICAL = 1,
  MAJOR = 2,
  MINOR = 3,

}
export enum ConflictType {
  NO_CONFLICT = 0,
  SUPPORTING_ARGUMENTS_NOT_FOUND = 1,
  SUPPORTING_ARGUMENTS_FOUND = 2,
  SOURCE_NOT_CHECKED = 3,
  SOURCE_NOT_VALID = 4,
  SOURCE_VALID = 5,
  LANGUAGE_NOT_OBJECTIVE = 6,
  LANGUAGE_OBJECTIVE = 7,
  RELEVANT_ARGUMENTS_UNSEEN = 8,
  RELEVANT_ARGUMENTS_SEEN = 9,
  CONDITIONS_OF_SOME_DEGREE_CONFLICTS = 10,
  SUPPORTING_ARGUMENTS_NOT_CHECKED = 11,
  LANGUAGE_NOT_CHECKED = 12,
  RELEVANT_ARGUMENTS_NOT_CHECKED = 13,
  DUPLICATE_FOUND = 14,
  DUPLICATE_NOT_FOUND = 15,
  DUPLICATE_NOT_CHECKED = 16
}